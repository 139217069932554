import Header from "../../components/Header/Header";
import "./About.css";
import "./AboutNew.css";
import Footer from "../../components/Footer/Footer";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import BrandCarousel from "../../components/BrandCarousel/BrandCarousel";
import homy from "./../../assets/images/logo/homy.png";
import { Zoom } from "react-reveal";
const About = () => {
  const parentTranslationPath = "Shared";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);
  const [activeIndex, setActiveIndex] = useState(4);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className={(activeIndex === 4 && "About-wrapper") || ""}>
        <Header about={true} />
        <div className="wrapper">
          <div className="content ">
            <div className="about-wraper-head d-flex-center">
              <div className="w-40 ">
                <div
                  onClick={() => {
                    setActiveIndex(4);
                  }}
                  className={`navbar-link-text almarai-normal-white-20-6px ${
                    activeIndex === 4 && "active primary-color"
                  }`}
                  style={{ fontSize: "18px" }}
                >
                  {t(`${translationPath}aboutCOP`)}
                </div>
                {/* <div
                  onClick={() => {
                    setActiveIndex(1);
                    scrollToBottom();
                  }}
                  className={`navbar-link-text almarai-normal-white-20-6px ${
                    activeIndex === 1 && "active primary-color"
                  }`}
                  style={{ fontSize: "18px" }}
                >
                  {t(`${translationPath}FILEcom`)}
                </div> */}
                <div
                  onClick={() => {
                    setActiveIndex(2);
                  }}
                  className={`navbar-link-text almarai-normal-white-20-6px ${
                    activeIndex === 2 && "active primary-color"
                  }`}
                  style={{ fontSize: "18px" }}
                >
                  {t(`${translationPath}Ourcustomers`)}
                </div>

                <div
                  className={`navbar-link-text almarai-normal-white-20-6px ${
                    activeIndex === 0 && "active primary-color"
                  }`}
                  onClick={() => {
                    setActiveIndex(0);
                  }}
                  style={{ fontSize: "18px" }}
                >
                  {t(`${translationPath}Our_partners`)}
                </div>
              </div>
            </div>
            <>
              {activeIndex === 0 && (
                <>
                  <div className="flexcenter">
                    <div style={{ width: "1280px" }}>
                      {" "}
                      <div className="d-flex_2">
                        <Zoom bottom>
                          <div
                            className="parnner-img"
                            onClick={() =>
                              window.open(
                                "https://www.scheer-group.com",
                                "_blank"
                              )
                            }
                          />{" "}
                        </Zoom>
                        <Zoom bottom>
                          <div
                            className="parnner-img2"
                            onClick={() =>
                              window.open("https://www.klingmann.com", "_blank")
                            }
                          />{" "}
                        </Zoom>
                        <Zoom bottom>
                          {" "}
                          <div
                            className="parnner-img3"
                            onClick={() =>
                              window.open("https://bw-engineers.com", "_blank")
                            }
                          />
                        </Zoom>{" "}
                        <Zoom zoom={0.5}>
                          <div
                            className="parnner-img4"
                            style={{ backgroundImage: `url(${homy})` }}
                            onClick={() =>
                              window.open("https://www.homy.build/en/", "_blank")
                            }
                          />
                        </Zoom>
                      </div>
                      <div className="how-wrpaer"></div>
                    </div>
                  </div>
                </>
              )}
              {activeIndex === 4 && (
                <div className="flexcenter">
                  <div style={{ width: "100%" }}>
                    {" "}
                    <div className="d-flex_2">
                      <div className="how--img" />
                    </div>
                    <div className="About-New-wrapper">
                      <div className="About-First-sec">
                        <div className="wi25">
                          <div className="main-sub-text">
                            {" "}
                            {t(`${translationPath}epdasoudee`)}
                          </div>
                          {t(`${translationPath}engask`)}
                        </div>
                           <div className="line-orange"></div>
                        <div className="wi75">
                          {" "}
                   <span className="">
                   <span className="color-epda"> {t(`${translationPath}epda`)}{" "}</span>    
                   {" "} 
                  {t(`${translationPath}dedsss`)} {" "}

                  <span className="color-epda">
                  {" "}     {t(`${translationPath}viosnsu`)}  {" "}
                </span>
                  {t(`${translationPath}dedsss2`)}
                  {" "}
  
                  

                </span>

      
                    
                        </div>
                      </div>

                      <div className="About-scaned-sec">
                        <div className="subAbout-scaned-sec">
                          <div className="height50 ourviso">
                            <div className="ourviso-text">
                              {" "}
                              {t(`${translationPath}dec3`)}
                            </div>

                            <div className="dec3dec33ew534">{t(`${translationPath}dec8`)}</div>
                          </div>
                          <div className="height50 ourmassage">
                            <div className="ourviso-mass">
                              {t(`${translationPath}dec4`)}
                            </div>
                            <div className="mass-sub-text">
                              {t(`${translationPath}dec7`)}
                            </div>
                          </div>
                        </div>

                        <div className="iamger">
                          {" "}
                          <div className="logo-vesionn-about" alt="logo" />{" "}
                        </div>
                      </div>

                      <div className="About-last-sec">
                        {" "}
                        <div className="sett"> </div>
                        <div className="rateus-wraper">
                          <div className="rateus-text">
                            {" "}
                            {t(`${translationPath}dec5`)}{" "}
                          </div>
                          <div className="rateus-list">
                            <div className="iamger">
                              <div className="text-list"> <div className="text-list-point"/>
                                  
                             <span> <span className="text-bold" >  {t(`${translationPath}respect`)} </span>  {t(`${translationPath}dec6`)}{" "} </span>   
                              </div>
                              <div className="text-list"> <div className="text-list-point"/>
                              <span> <span className="text-bold">  {t(`${translationPath}teamwork`)} </span>  {t(`${translationPath}dec9`)}{" "} </span>   
                             
                              </div>
                              <div className="text-list"> <div className="text-list-point"/>
                              <span> <span className="text-bold">  {t(`${translationPath}integrity`)} </span>  {t(`${translationPath}dec10`)}{" "} </span>  
                              </div>
                            </div>

                            <div className="iamger">
                              <div className="text-list"> <div className="text-list-point"/>
                                {t(`${translationPath}`)}

                                <span> <span className="text-bold">  {t(`${translationPath}quality`)} </span>  {t(`${translationPath}dec11`)}{" "} </span>  

                              </div>
                              <div className="text-list"> <div className="text-list-point"/>
                              <span> <span className="text-bold">  {t(`${translationPath}creativity`)} </span>  {t(`${translationPath}dec12`)}{" "} </span>  
                              </div>
                            </div>

                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="how-wrpaer flexcenter-how">
                      <div className="how-uss-text-about-us ">
                        {t(`${translationPath}epda`)}
                      </div>

                      <div className="d-flex">
                        <div className="w-50">
                         <div className="how-uss-text-white">
                            {t(`${translationPath}dec1`)}
                          </div> 
                          <div className="">
                            <span
                              className="how-color "
                              style={{ width: "74px" }}
                            >
                              {t(`${translationPath}dec4`)} :{" "}
                            </span>
                            &nbsp;
                            <span className="how-uss-text-white">
                              {" "}
                              {t(`${translationPath}dec7`)}
                            </span>
                          </div>
                          <div className="">
                            <span className="how-color ">
                              {t(`${translationPath}dec3`)} :{" "}
                            </span>
                            &nbsp;
                            <span className="how-uss-text-white">
                              {" "}
                              {t(`${translationPath}dec8`)}
                            </span>
                          </div>
                        </div>

                        <div className="w-50">
                          <div className="">
                            <span className="how-color ">
                              {t(`${translationPath}dec5`)} :{" "}
                            </span>
                          </div>
                          <div className="">
                            <span className="how-color ">1 -</span>
                            &nbsp;
                            <span className="how-uss-text-white">
                              {" "}
                              {t(`${translationPath}dec6`)}
                            </span>
                          </div>
                          <div className="">
                            <span className="how-color ">2 -</span>
                            &nbsp;
                            <span className="how-uss-text-white">
                              {" "}
                              {t(`${translationPath}dec9`)}
                            </span>
                          </div>
                          <div className="">
                            <span className="how-color ">3 -</span>
                            &nbsp;
                            <span className="how-uss-text-white">
                              {" "}
                              {t(`${translationPath}dec10`)}
                            </span>
                          </div>
                          <div className="">
                            <span className="how-color ">4 -</span>
                            &nbsp;
                            <span className="how-uss-text-white">
                              {" "}
                              {t(`${translationPath}dec11`)}
                            </span>
                          </div>
                          <div className="">
                            <span className="how-color ">5 -</span>
                            &nbsp;
                            <span className="how-uss-text-white">
                              {" "}
                              {t(`${translationPath}dec12`)}
                            </span>
                          </div>
                        </div>
     
                      </div>
                    </div> */}
                  </div>
                </div>
              )}

              {activeIndex === 1 && (
                <>
                  <div>
                    <embed
                      src="https://www.ebdaasa.com.sa/wp-content/uploads/2021/03/Ebdaasa%20210311%20LIGHT%20CR.pdf"
                      type="application/pdf"
                      width="100%"
                      height="700px"
                    />
                  </div>
                </>
              )}
              {activeIndex === 2 && (
                <>
                  <BrandCarousel />
                  {/* <LogoCarousel logos={logos} /> */}
                </>
              )}
            </>
          </div>
        </div>
        <Footer transparent={true} />
      </div>
    </>
  );
};

export default About;
