import React, { useState, useEffect, useCallback, useMemo } from "react";
import "./Carousel.css"; // Assuming you've saved the CSS in a file named Carousel.css
import icon_close from "./../../../../assets/images/icon/arrawo.png";
import sliderImg2 from "./../../../../assets/images/slider/back2024 (2).jpg";
import sliderImg4 from "./../../../../assets/images/slider/back2024 (4).jpg";
import sliderImg13 from "./../../../../assets/images/slider/back2024 (13).jpg";
import sliderImg16 from "./../../../../assets/images/slider/back2024 (16).jpg";
import anmationlogo from "./../../../../assets/images/logo/anmationlogo.gif";
import sliderImg22 from "./../../../../assets/images/slider/back2024 (22).jpg";
import sliderImg23 from "./../../../../assets/images/slider/back2024 (23).jpg";
import sliderImg244 from "./../../../../assets/images/slider/back2024 (13) - Copy.jpg";
import saman from "./../../../../assets/images/slider/saman.jpg";
import asd from "./../../../../assets/images/slider/12asd.jpg";

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const images = useMemo(
    () => [
      sliderImg2,
      sliderImg4,
      sliderImg16,
      sliderImg13,
      asd,
      sliderImg22,
      sliderImg244,
      saman,
      sliderImg23,
    ],
    []
  );

  const updateCarousel = useCallback(
    (newIndex) => {
      setCurrentIndex((newIndex + images.length) % images.length);
    },
    [images]
  );

  const goToNextImage = () => {
    updateCarousel(currentIndex + 1);
  };

  const goToPreviousImage = () => {
    updateCarousel(currentIndex - 1);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 37) {
        goToPreviousImage();
      } else if (event.keyCode === 39) {
        goToNextImage();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [goToNextImage, goToPreviousImage]);

  useEffect(() => {
    const interval = setInterval(() => {
      goToNextImage();
    }, 6000);

    // Simulate loading completion after 2 seconds
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => {
      clearInterval(interval);
      clearTimeout(loadingTimeout);
    };
  }, [currentIndex, goToNextImage]);

  return (
    <div className="mian-carousel">
      <div className={`carousel ${isLoading ? "loading" : ""}`}>
        {/* Carousel content */}
        {/* Left arrow */}
        <div
          style={{ backgroundImage: `url(${icon_close})` }}
          onClick={goToPreviousImage}
          className="prev fa-solid fa-arrow-left"
          alt="icon_close"
        ></div>

        {/* Carousel indicators */}
        <div className="carousel-indicators">
          {images
            .slice()
            .reverse()
            .map((_, index) => (
              <div
                key={index}
                className={`carousel-indicator ${
                  currentIndex === images.length - 1 - index ? "active" : ""
                }`}
                onClick={() => updateCarousel(images.length - 1 - index)}
              ></div>
            ))}
        </div>

        {/* Right arrow */}
        <div
          style={{ backgroundImage: `url(${icon_close})` }}
          onClick={goToNextImage}
          className="next fa-solid fa-arrow-left"
          alt="icon_close"
        ></div>

        {/* Images */}
        {images.map((image, index) => (
          // eslint-disable-next-line jsx-a11y/img-redundant-alt
          <img
            key={index}
            src={image}
            alt={`image ${index + 1}`}
            className={currentIndex === index ? "active" : ""}
          />
        ))}
      </div>
      {isLoading && (
        <div className="loading-screen">
          <div className="loading-logo">
            <img
            src={anmationlogo}
            alt='anmationlogo'
            className='anmationlogo'
          />
            
            </div>
        </div>
      )}
    </div>
  );
};

export default Carousel;
