import React, { useEffect, useState } from "react";
import "./HomePage.css";
import logoImage from "./../../assets/images/logo/Viosn2030.png";
import faceIcon from "./../../assets/images/icon/face.svg";
import insgIcon from "./../../assets/images/icon/insg.svg";
import linkendIcon from "./../../assets/images/icon/linkend.svg";
import youtubeIcon from "./../../assets/images/icon/youtube.svg";
import Mask from "./../../assets/images/background_img/Mask group.png";
import Mask23roup from "./../../assets/images/background_img/Mask23roup.png";
import atraciorpng from "./../../assets/images/background_img/atraciorpng.png";
import Vback from "./../../assets/images/background_img/Mask32332group.png";
import back from "./../../assets/images/background_img/back.png";
import back3 from "./../../assets/images/background_img/back3.png";
import Carousel from "./utilteis/Carousel/Carousel";
import art from "./../../assets/images/background_img/art.png";
import iso from "./../../assets/images/shapes/iso.png";
import iso2 from "./../../assets/images/shapes/iso2.png";
import iaf from "./../../assets/images/shapes/iaf.png";
import nye from "./../../assets/images/shapes/nye.png";
import icon_close from "./../../assets/images/icon/dwon.png";
import icon_closeup from "./../../assets/images/icon/up.png";
import Header from "../../components/Header/Header";
import { useTranslation } from "react-i18next";
import { Bounce } from "react-reveal";
import { useNavigate } from "react-router-dom";
const HomePage = () => {
  const parentTranslationPath = "Shared";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);
  const [scrollToTop, setScrollToTop] = useState(true);
  const language =
    (JSON.parse(localStorage.getItem("EbdaaWebSiteLocalization")) &&
      JSON.parse(localStorage.getItem("EbdaaWebSiteLocalization"))
        .currentLanguage) ||
    "ar";
  const handleButtonClick = () => {
    const scrollOptions = {
      top: scrollToTop ? document.documentElement.scrollHeight : 0,
      behavior: "smooth",
    };

    // Adding a delay of 300 milliseconds for a slower scroll
    setTimeout(() => {
      window.scrollTo(scrollOptions);
      setScrollToTop(!scrollToTop);
    }, 300);
  };
const maklek= 
[
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/valles.png",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/1111.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/01a.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/03.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/06.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/021.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/32dsfd.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/01a.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/facade.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/B01_1.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/B01_2.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/022.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/SY_05.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/camera 4.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/CAMERA 1.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/021.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/2w342r2.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/eqw4324234321423.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/low234es.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/low234es.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/preewrewrn.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/3244234234reqwfewf.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/aqewrdqwrwq.jpg",
  "https://www.ebdaasa.com.sa/MoreData/ImagesMore/bld234341.jpg"
]
  const navigate = useNavigate();
  const redirectToprojects = () => {
    navigate("/projects");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  function SocialMediaBox() {
    return (
      <div className="Social-media-box">
        <img
          src={faceIcon}
          className="Social-media-icon"
          alt="Facebook"
          onClick={() =>
            window.open(
              "https://www.facebook.com/AlebdaaAlsaudi/?locale=ar_AR",
              "_blank"
            )
          }
        />
        <img
          src={insgIcon}
          className="Social-media-icon"
          alt="Instagram"
          onClick={() =>
            window.open("https://twitter.com/alebdaa_alsaudi?lang=ar", "_blank")
          }
        />
        <img
          src={linkendIcon}
          className="Social-media-icon"
          alt="LinkedIn"
          onClick={() =>
            window.open(
              "https://sa.linkedin.com/in/الابداع-السعودي-للاستشارات-الهندسية-7a19ba13a",
              "_blank"
            )
          }
        />
        <img
          src={youtubeIcon}
          className="Social-media-icon"
          alt="YouTube"
          onClick={() =>
            window.open(
              "https://www.youtube.com/@al-ebdaaalsaudiconsultants554",
              "_blank"
            )
          }
        />
      </div>
    );
  }

  function Counter({ initialValue }) {
    const [count, setCount] = useState(initialValue || 0);
    const [triggered, setTriggered] = useState(false);
    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY >= 80 && !triggered) {
          setTriggered(true);
          window.removeEventListener("scroll", handleScroll); // Remove the event listener
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => window.removeEventListener("scroll", handleScroll);
    }, [triggered]);

    useEffect(() => {
      if (triggered) {
        const target = initialValue;
        let currentCount = 0;
        const increment = target / 200; // You can adjust the speed here

        const timer = setInterval(() => {
          currentCount += increment;
          setCount(Math.ceil(currentCount));
          if (currentCount >= target) {
            clearInterval(timer);
            setCount(target);
          }
        }, 10);

        return () => clearInterval(timer);
      }
    }, [initialValue, triggered]);

    return <h2>{count}</h2>;
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop >= 2000) {
        setScrollToTop(false);
      } else {
        setScrollToTop(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="HomePage-wrapper">
        <Header home={true} isabsolute={true} />

        <div className="BackgroundSlider-LIST">
          {" "}
          <Carousel />
        </div>

        <div className="width-100 cc-dsf mobileview">
          <div className="fethcersss">
            {/* <Bounce left={language === "ar"} right={language !== "ar"}> */}
              <div className="how-are-we-dec">
                {" "}
                <span className="color-epda">
                {"  "}{t(`${translationPath}epda`)}{"  "}
                </span>
                {" "} {t(`${translationPath}about-des`)}{" "}
                <span className="color-epda">
                  {t(`${translationPath}viosnsu`)}
                </span>
              </div>{" "}
            {/* </Bounce> */}
          </div>
          <img
            src={Vback}
            height="200px"
            className="Mask_view2"
            alt="rating_star"
          />
        </div>
        <div className="width-height-100 mobileview">
              <img
                src={Mask23roup}
                height="200px"
                className="Mask_view"
                alt="rating_star"
              />
            </div>
        <div
          style={{
            backgroundImage: `url(${scrollToTop ? icon_close : icon_closeup})`,
            position: "fixed",
            right: "36px",
            backgroundRepeat: "no-repeat",
            bottom: "61px",
            cursor: "pointer",
            height: "44px",
            width: "44px",
            backgroundSize: "100%",
            zIndex: 3,
          }}
          onClick={handleButtonClick}
          className="scrollBootn"
          alt="icon_ose"
        ></div>

        <div className="boxcenter-contenar center-contenar">
          <div className="saction_2">
            <div className="img_2">
              <img
                src={atraciorpng}
                className="atraciorpng"
                alt="rating_star"
              />
            </div>
            <div className="center-scer">
              <div
                className="rating_Top-1"
                style={{
                  backgroundImage: `url(${back})`,
                  backgroundSize: "contain",
                }}
              >
                <div className="plis2">+</div>
                <div className="rating_value">
                  {" "}
                  <Counter initialValue={20} />
                </div>
                <div className="rating_text">
                  {t(`${translationPath}years_of_experience`)}
                </div>
              </div>
            </div>
            <div className="width-height-100">
              <img
                src={Mask}
                height="200px"
                className="Mask_view"
                alt="rating_star"
              />
            </div>
          </div>
        </div>

        <div className="center-contenar-mobile">
          <div className="saction_2">
            <div className="center-scer">
              <div
                className="rating_Top-1"
                style={{
                  backgroundImage: `url(${back})`,
                  backgroundSize: "contain",
                }}
              >
                <div className="plis2">+</div>
                <div className="rating_value">
                  {" "}
                  <Counter initialValue={20} />
                </div>
                <div className="rating_text">
                  {t(`${translationPath}years_of_experience`)}
                </div>
              </div>
            </div>
            <div className="img_2">
              <img
                src={atraciorpng}
                className="atraciorpng"
                alt="rating_star"
              />
            </div>
            <div className="width-height-100 webview">
              <img
                src={Mask}
                height="200px"
                className="Mask_view"
                alt="rating_star"
              />
            </div>
          </div>
        </div>





        <div className="center-contenar">
          <div className="saction_3">
            <div
              className="fethcer"
              style={{
                backgroundImage: `url(${back3})`,
                objectFit: "cover",
                backgroundSize: "cover",
              }}
            >
              {/* <Bounce left={language !== "ar"} right={language === "ar"}> */}
                <div className="fethcer-1">{t(`${translationPath}vison`)}</div>
                <div className="fethcer-2 color-white">
                  {t(`${translationPath}ff-yy`)}
                </div>
              {/* </Bounce> */}
            </div>

            <div className="img_22">
              <img src={art} className="centimg" alt="rating_star" />
            </div>

            <div className="width-100 cc-dsf webview">
              <div className="fethcersss">
                {/* <Bounce left={language === "ar"} right={language !== "ar"}> */}
                  <div className="how-are-we-dec">
                    {" "}
                    <span className="color-epda">
                      {t(`${translationPath}epda`)}{" "}
                    </span>
                    {" "}  {t(`${translationPath}about-des`)}{" "}
                    <span className="color-epda">
                      {t(`${translationPath}viosnsu`)}
                    </span>
                  </div>{" "}
                {/* </Bounce> */}
              </div>
              <img
                src={Vback}
                height="200px"
                className="Mask_view2"
                alt="rating_star"
              />
            </div>
          </div>
        </div>
        <div className="center-contenar TABELT-VIEW">
          <div className="saction_3">
            <div
              className="fethcer"
    
            >
                            <img
                src={Mask}
                height="200px"
                className="Mask_view2"
                alt="rating_star"
              />
            </div>

            <div className="width-100 cc-dsf TABELET-des">
              <div className="fethcersss">
                {/* <Bounce left={language === "ar"} right={language !== "ar"}> */}
                  <div className="how-are-we-dec">
                    {" "}
                    <span className="color-epda">
                      {t(`${translationPath}epda`)}{" "}
                    </span>
                    {t(`${translationPath}about-des`)}{" "}
                    <span className="color-epda">
                      {t(`${translationPath}viosnsu`)}
                    </span>
                  </div>{" "}
                {/* </Bounce> */}
              </div>
              <img
                src={Vback}
                height="200px"
                className="Mask_view2"
                alt="rating_star"
              />
            </div>
          </div>
        </div>
        <div className="center-contenar">
          <div className="rating_Top_section_wrapper">
            <div className="rating_img_town"></div>
            <div className="rating-box2">
              <div className="rating_Top">
                <div className="plis">+</div>
                <div className="rating_value">
                  {" "}
                  <Counter initialValue={200} />
                </div>
                <div className="completed_project-text">
                  {t(`${translationPath}completed_project`)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="center-contenar">
          <div className="rating_section_wrapper">
          
            <div className="rating_item rating_item-2">
              <img src={iaf} className="iso-icon" alt="main-icon" />
            </div>

            <div className="rating_item rating_item-1">
              <img src={nye} className="rating_nye" alt="rating_star" />
              <div className="review_text">
                {t(`${translationPath}Arab_Award`)}
              </div>
            </div>
            
          
            <div className="rating_item">
              <img src={iso2} className="main-icon" alt="main-icon" />
            </div>
            <div className="rating_item">
              <img src={iso} className="main-icon" alt="main-icon" />
            </div>
            
          </div>
        </div>
        <div className="footer-home">
          <div className="container-bbt-view-more">
            <button onClick={() => redirectToprojects()} className="view-mote">
              {t(`${translationPath}explore_our_work`)}
            </button>
          </div>
          <div>
            <img src={logoImage} className="logo-Viosn2030" alt="logo" />
          </div>
          <div>{t(`${translationPath}copyright`)}</div>
          <SocialMediaBox />
        </div>
      </div>
    </>
  );
};

export default HomePage;
