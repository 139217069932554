import { useEffect, useState } from "react";
import Careers_img from "./../../assets/images/background_img/Careers_img.png";
import "./Careers.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Careers = () => {
  const parentTranslationPath = "Shared";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);
  const [ViewMassge, setViewMassge] = useState(null);

  const navigate = useNavigate();
  const redirectToprojects = () => {
    navigate("/vacancies");
  };

  const nofile = t(`${translationPath}nofile`);
  const [fileName, setFileName] = useState(nofile);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [department, setDepartment] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [showViewMassge, setshowViewMassge] = useState(true);
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    setTimeout(() => {
      setViewMassge(null);
      setshowViewMassge(true);
    }, 6000);
  }, [ViewMassge]);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    // Check if any of the required fields are empty
    if (!fullName || !email || !phoneNumber || !department || !message) {
      // If any field is empty, do nothing and return
      return;
    }

    // Set success message
    setViewMassge(t(`${translationPath}SendDone`));
    setshowViewMassge(false);
    const formData = new FormData();
    formData.append("fullName", fullName);
    formData.append("email", email);
    formData.append("phoneNumber", phoneNumber);
    formData.append("department", department);
    formData.append("message", message);
    formData.append("file", file);

    try {
      const response = await fetch("https://scfec.com/CareersSend.php", {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      // Reset form fields after successful submission
      setFullName("");
      setEmail("");
      setPhoneNumber("");
      setDepartment("");
      setMessage("");
      setFile(null);
    } catch (error) {
      console.error("Error:", error);
      // Reset form fields if an error occurs
      setFullName("");
      setEmail("");
      setPhoneNumber("");
      setDepartment("");
      setMessage("");
      setFile(null);
    }
  };

  // const careersData = [
  //   {
  //     jobName: "مهندس مدني-المملكة العربية السعودية تصميم المبنى",
  //     jobPart: "هندسة",
  //     jobLocation: "الرياض",
  //   },
  //   {
  //     jobName: "مهندس مدني-المملكة العربية السعودية تصميم المبنى",
  //     jobPart: "هندسة",
  //     jobLocation: "الرياض",
  //   },
  //   {
  //     jobName: "مهندس مدني-المملكة العربية السعودية تصميم المبنى",
  //     jobPart: "هندسة",
  //     jobLocation: "الرياض",
  //   },
  //   {
  //     jobName: "مهندس مدني-المملكة العربية السعودية تصميم المبنى",
  //     jobPart: "هندسة",
  //     jobLocation: "الرياض",
  //   },
  //   {
  //     jobName: "مهندس مدني-المملكة العربية السعودية تصميم المبنى",
  //     jobPart: "هندسة",
  //     jobLocation: "الرياض",
  //   },
  //   {
  //     jobName: "مهندس مدني-المملكة العربية السعودية تصميم المبنى",
  //     jobPart: "هندسة",
  //     jobLocation: "الرياض",
  //   },
  //   {
  //     jobName: "مهندس مدني-المملكة العربية السعودية تصميم المبنى",
  //     jobPart: "هندسة",
  //     jobLocation: "الرياض",
  //   },
  //   {
  //     jobName: "مهندس مدني-المملكة العربية السعودية تصميم المبنى",
  //     jobPart: "هندسة",
  //     jobLocation: "الرياض",
  //   },
  // ];
  return (
    <>
      <div className="Careers-wrapper">
        <div className="wrapper">
          <div className="content">
            {/* <div className="flex-center main-text primary-color">
                            <div className="navbar-link-text almarai-normal-white-20-6px active-title" style={{ fontSize: "30px" }}>
                                {t(`${translationPath}Careers`)}
                            </div>
                        </div> */}
            <div className="Careers-container">
              {/* <div className="Careers_img">
                <div
                  className="rating_Top"
                  style={{
                    backgroundImage: `url(${Careers_img})`,
                    objectFit: "contain",
                    backgroundSize: "cover",
                    width: "100%",
                    height: "45rem",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top",
                  }}
                >
                <div className="area" >
                                        <ul className="circles">
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div >
                </div>
              </div>
            <div className="Careers_box">
                                {careersData.map((item, index) => (
                                    <div key={index} className="Careers_item">
                                        <div className="job-name">{item.jobName}</div>
                                        <div className="job-part">{item.jobPart}</div>
                                        <div className="job-loaction">{item.jobLocation}</div>
                                        <div className="more-det" onClick={redirectToprojects}>
                                            {t(`${translationPath}more`)} &nbsp;
                                            <span className="arraw">
                                                {i18next.language !== 'en' ? <> &#8592;</> : <> &#8594;</>}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>  */}
              <div>
                <div className="details-carre">
                  <div className="join-use-box">
                    <div className="text-ebd">
                      {t(`${translationPath}join`)}
                    </div>

                    <div className="text-white">
                      {t(`${translationPath}des-car-1`)}
                    </div>
                    <div className="text-white">
                      {t(`${translationPath}des-car-2`)}
                    </div>
                    <div className="text-white" style={{ maxWidth: "29rem" }}>
                      {t(`${translationPath}des-car-3`)}
                    </div>
                  </div>

                  <div className="det-box det-box-wrapper">
                    <div className="text-ebd-2">
                      {" "}
                      {t(`${translationPath}sends`)}{" "}
                    </div>
                    <div className="d-flex-s">
                      <div className="input-wrapper">
                        <input
                          type="text"
                          placeholder={t(`${translationPath}fullname`)}
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </div>
                      <div className="input-wrapper">
                        <input
                          type="text"
                          placeholder={t(`${translationPath}emaill`)}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="d-flex-s">
                      <div className="input-wrapper">
                        <input
                          type="text"
                          placeholder={t(`${translationPath}phonenum`)}
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                      </div>
                      <div className="input-wrapper">
                        <input
                          type="text"
                          placeholder={t(`${translationPath}depar`)}
                          value={department}
                          onChange={(e) => setDepartment(e.target.value)}
                        />
                      </div>
                    </div>
                    <div
                      className="input-wrapper"
                      style={{ padding: "10px 1px 3px" }}
                    >
                      <textarea
                        rows="4"
                        cols="30"
                        placeholder={t(`${translationPath}massg`)}
                        style={{ resize: "vertical", width: "100%" }}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                    </div>
                    <div style={{ marginTop: "20px", width: "100%" }}>
                      <div className="text-glopel">
                        {" "}
                        {t(`${translationPath}cvv`)}
                      </div>
                      <div className="d-flex" style={{ marginTop: "15px" }}>
                        <div className="button-wrapper">
                          <div className="overlap-5">
                            <div className="text-wrapper-10">
                              {" "}
                              {t(`${translationPath}uplodefile`)}{" "}
                            </div>
                            <input
                              type="file"
                              className="file-input"
                              onChange={handleFileChange}
                              accept=".pdf,.doc,.docx"
                            />
                          </div>
                        </div>
                        <div className="uplodetext">
                          {file ? file.name : "No File"}
                        </div>
                      </div>
                      <div className="text-gree" style={{ marginTop: "15px" }}>
                        {t(`${translationPath}alow`)}
                      </div>
                      <div className="save-vv">
                        {showViewMassge && (
                          <div className="button-wrapper">
                            <div className="overlap-5">
                              <div
                                className="text-wrapper-10"
                                onClick={handleSubmit}
                              >
                                {" "}
                                {t(`${translationPath}sennd`)}
                              </div>
                            </div>
                          </div>
                        )}
                        {ViewMassge !== null && (
                          <button
                            style={{ color: "white" }}
                            className="app-form-button"
                          >
                            {ViewMassge}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
};

export default Careers;
