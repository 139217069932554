import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./Carousel.css";
import icon_close from "./../../assets/images/icon/colse.png";
import rhiteicon from "./../../assets/images/icon/arrawo.png";
import lefticon from "./../../assets/images/icon/arrawo - Copy.png";

const Carousel = ({ images, isOpen, onClose, cIndex }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [filteredImages, setFilteredImages] = useState([]);

  const openLightbox = (index) => {
    setCurrentIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    onClose(false);
    setCurrentIndex(0);
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % filteredImages.length);
  };

  const prevImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + filteredImages.length) % filteredImages.length
    );
  };

  useEffect(() => {
    setLightboxOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setCurrentIndex(cIndex);
  }, [cIndex]);

  useEffect(() => {
    // Filter out images with mp4 format
    const filtered = images.filter(image => !image.endsWith('.mp4'));
    setFilteredImages(filtered);
  }, [images]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 37) {
        prevImage();
      } else if (event.keyCode === 39) {
        nextImage();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [filteredImages]); // Update effect when filteredImages changes

  return (
    <div>
      <div className={`lightbox ${lightboxOpen ? "open" : ""}`}>
        <div className="close-btn ">
          <img
            loading="lazy"
            width="40px"
            onClick={closeLightbox}
            className="cloce-icon"
            src={icon_close}
            alt="icon_close"
          />
        </div>
        {filteredImages && filteredImages.length > 1 && (
          <button className="prev-btn">
            {" "}
            <img
              loading="lazy"
              width="40px"
              onClick={prevImage}
              className="cloce-icon"
              src={lefticon}
              alt="icon_close"
            />
          </button>
        )}
        <img src={filteredImages[currentIndex]} alt={`Image ${currentIndex + 1}`} />
        {filteredImages && filteredImages.length > 1 && (
          <button className="next-btn">
            {" "}
            <img
              loading="lazy"
              width="40px"
              onClick={nextImage}
              className="cloce-icon"
              src={rhiteicon}
              alt="icon_close"
            />
          </button>
        )}
      </div>
      <div className="gallery">
        {filteredImages.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${index + 1}`}
            onClick={() => openLightbox(index)}
          />
        ))}
      </div>
    </div>
  );
};

Carousel.propTypes = {
  images: PropTypes.array.isRequired,
};

export default Carousel;
