import React from 'react';
import './Footer.css';
import faceIcon from './../../assets/images/icon/face.svg';
import insgIcon from './../../assets/images/icon/insg.svg';
import linkendIcon from './../../assets/images/icon/linkend.svg';
import youtubeIcon from './../../assets/images/icon/youtube.svg';
import { useTranslation } from 'react-i18next';

const Footer = ({ transparent }) => {
  const parentTranslationPath = 'Shared';
  const translationPath = '';
  const { t } = useTranslation(parentTranslationPath);
  return (
<>
      <div className={transparent ? 'footer-transparent' : 'footer'}>
        <div className="copyright">{t(`${translationPath}copyright`)}</div>
        <div className="social-media-container">
          <img src={faceIcon} className="social-media-icon" alt="Facebook" onClick={() => window.open('https://www.facebook.com/AlebdaaAlsaudi/?locale=ar_AR', '_blank')} />
          <img src={insgIcon} className="social-media-icon" alt="Instagram" onClick={() => window.open('https://twitter.com/alebdaa_alsaudi?lang=ar', '_blank')} />
          <img src={linkendIcon} className="social-media-icon" alt="LinkedIn" onClick={() => window.open('https://sa.linkedin.com/in/الابداع-السعودي-للاستشارات-الهندسية-7a19ba13a', '_blank')} />
          <img src={youtubeIcon} className="social-media-icon" alt="YouTube" onClick={() => window.open('https://www.youtube.com/@al-ebdaaalsaudiconsultants554', '_blank')} />
        </div>
      </div>
    </>
  );
}
export default Footer;
