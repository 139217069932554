import Header from "../../components/Header/Header";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Slideshow from "../../components/Slideshow";
import Carousel from "../../components/Carousel/Carousel";
import LazyLoad from "react-lazyload";
import "./OurProjects.css";
import Footer from "../../components/Footer/Footer";
import icon_close from "./../../assets/images/icon/colse.png";
import projects from "./ProjectsData";

const OurProjects = () => {
  const parentTranslationPath = "Shared";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [ActiveItem, setActiveItem] = useState("");
  const [viewMore, setviewMore] = useState(false);
  const [currentIndex, setcurrentIndex] = useState(0);
  const language =
    (JSON.parse(localStorage.getItem("EbdaaWebSiteLocalization")) &&
      JSON.parse(localStorage.getItem("EbdaaWebSiteLocalization"))
        .currentLanguage) ||
    "ar";
  const showDrawer = (item) => {
    setIsDrawerOpen(true);
    setActiveItem(item);
  };

  const hideDrawer = () => {
    setIsDrawerOpen(false);
    setActiveItem("");
  };

  const [PageDataProject, setPageDataProject] = useState([]);
  const [NewPageDataProject, setNewPageDataProject] = useState(null);

  function openLink() {
    if (NewPageDataProject !== null) {
      setviewMore(true);
      setPageDataProject((prevState) => [...prevState, ...NewPageDataProject]);
    }
    // window.open(

    //   "https://saudiebdaa-my.sharepoint.com/:b:/g/personal/manage_ebdaasa_com_sa/EWDVPXFX--RNviMnBGvQvsUBSYqPTqBJYSGQNRiZiYfHBQ?e=54gz0J",
    //   "_blank"
    // );
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (index) => {
    setcurrentIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [showAnimation, setShowAnimation] = useState(false);
  useEffect(() => {
    setShowAnimation(true);
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://www.ebdaasa.com.sa/MoreData/getAPi.php"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const jsonData = await response.json();
      // Merge jsonData with existing PageDataProject state
      setNewPageDataProject(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    fetchData();
  }, []);

  
  useEffect(() => {
    setPageDataProject(projects);
  }, []);



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="OurProjects-wrapper">
        <Header projects={true} />
        <div className="wrapper">
          <div className="content">
            <div className="flex-center main-text primary-color">
              <div
                className="navbar-link-text almarai-normal-white-20-6px active-title"
                style={{ fontSize: "21px" }}
              >
                {t(`${translationPath}our_projects`)}
              </div>
            </div>
            <div className="img-container">
              <div className={`img-item-wrpaer ${showAnimation ? "show" : ""}`}>
                <div className="app"></div>
                {PageDataProject.map((project, index) => (
                  <div
                    key={index}
                    className={`relative-position ${
                      showAnimation ? "show" : ""
                    }`}
                  >
                    <LazyLoad height={200} offset={100} once>
                      {/* <img  loading="lazy" className="img-item" src={backBck} alt={project.alt} /> */}
                      <img
                        onClick={() => showDrawer(project)}
                        loading="lazy"
                        className="img-item"
                        alt={project.alt}
                        src={
                          Array.isArray(project.imageSrc)
                            ? project.imageSrc[0]
                            : project.imageSrc
                        }
                      />
                    </LazyLoad>

                    <div className="img-item-text">
                      <div className="title title-text-project">
                        {(language === "en" &&
                          project &&
                          project.title_en &&
                          project.title_en) ||
                          t(project.title)}
                      </div>
                      <div className="title title-description-project">
                        {(language === "en" &&
                          project &&
                          project.description_en &&
                          project.description_en) ||
                          t(project.description)}
                      </div>
                    </div>
                  </div>
                ))}
                {viewMore === false && (
                  <div
                    className="container-bbt-view-more"
                    style={{ marginTop: "4rem" }}
                  >
                    <button onClick={openLink} className="view-mote">
                      {t(`${translationPath}view_more_projects-pag`)}
                    </button>
                  </div>
                )}
              </div>

              {/* <div
                className="container-bbt-view-more"
                style={{ marginTop: "4rem" }}
              >
                <button onClick={openLink} className="view-mote">
                  {t(`${translationPath}view_more_projects`)}
                </button>
              </div> */}
            </div>

            {
              <div id="body" className={isDrawerOpen ? "show-drawer" : ""}>
                <div id="drawer">
                  <div className="x-icon">
                    <img
                      loading="lazy"
                      width="40px"
                      onClick={hideDrawer}
                      className="cloce-icon"
                      src={icon_close}
                      alt="icon_close"
                    />
                  </div>
                  <div className="slider-content">
                    <div className="title main-text-slider primary-color">
                      {(language === "en" &&
                        ActiveItem &&
                        ActiveItem.title_en &&
                        ActiveItem.title_en) ||
                        t(ActiveItem.title)}
                    </div>
                    <div
                      className="title title-description-project"
                      style={{ marginBottom: "20px" }}
                    >
                      {(language === "en" &&
                        ActiveItem &&
                        ActiveItem.description_en &&
                        ActiveItem.description_en) ||
                        t(ActiveItem.description)}
                    </div>
                    <div className="cENTER">
                      {ActiveItem && !Array.isArray(ActiveItem.imageSrc) ? (
                        <img
                          loading="lazy"
                          width={"342px"}
                          height={"342px"}
                          className="slider_img"
                          src={ActiveItem.imageSrc}
                          onClick={() => openModal(0)}
                          alt={ActiveItem.alt}
                        />
                      ) : (
                        <>
                          {ActiveItem && (
                            <Slideshow
                              images={
                                Array.isArray(ActiveItem.imageSrc)
                                  ? ActiveItem.imageSrc.slice(1) // Remove the first item in the array
                                  : ActiveItem.imageSrc && [ActiveItem.imageSrc]
                              }
                              onClickopenModal={(currentIndex) =>
                                openModal(currentIndex)
                              }
                            />
                          )}
                        </>
                      )}
                    </div>
                    <div className="title main-text-slider ">
                      {(language === "en" &&
                        ActiveItem &&
                        ActiveItem.descriptionTitle_en &&
                        ActiveItem.descriptionTitle_en) ||
                        t(ActiveItem.descriptionTitle)}
                    </div>
                    {
                      <div className="title main-text-slider">
                        {(language === "en" &&
                          ActiveItem &&
                          ActiveItem.size_en &&
                          ActiveItem.size_en) ||
                          t(ActiveItem.size)}
                      </div>
                    }
                    <div className="flex-center-w">
                      <div
                        className={`about-title  about-text-slider  ${ActiveItem.className}`}
                      >
                        {(ActiveItem.id == 1 &&
                          ((language === "en" &&
                            ActiveItem &&
                            ActiveItem.about_en &&
                            ActiveItem.about_en) ||
                            t(ActiveItem.about))) ||
                          ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div id="overlay" onClick={hideDrawer}></div>
              </div>
            }
          </div>
          <Footer transparent={true} />
          {ActiveItem && (
            <Carousel
              isOpen={isModalOpen}
              onClose={closeModal}
              cIndex={currentIndex || 0}
              images={
                Array.isArray(ActiveItem.imageSrc)
                  ? ActiveItem.imageSrc.slice(1) // Remove the first item in the array
                  : ActiveItem.imageSrc && [ActiveItem.imageSrc]
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default OurProjects;
