import news_1 from "./../../assets/images/shapes/news1 (1).png";
import icon_close from "./../../assets/images/icon/colse.png";
import news_3 from "./../../assets/images/shapes/news1 (3).png";
import news_4 from "./../../assets/images/shapes/news1 (4).png";
import designConsultancyImage from "./../../assets/images//shapes/news1 (2).png";

import "./News.css";
import { Zoom } from "react-reveal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const News = () => {
  const parentTranslationPath = "Shared";
  const translationPath = "";
  const language =
    (JSON.parse(localStorage.getItem("EbdaaWebSiteLocalization")) &&
      JSON.parse(localStorage.getItem("EbdaaWebSiteLocalization"))
        .currentLanguage) ||
    "ar";
  const { t } = useTranslation(parentTranslationPath);

  const [projects, setprojects] = useState([]);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://www.ebdaasa.com.sa/News/NewsGetAPI.php"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const jsonData = await response.json();
      // Merge jsonData with existing PageDataProject state
      setprojects(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const [ActiveItem, setActiveItem] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);


  const showDrawer = (item) => {
    setIsDrawerOpen(true);
    setActiveItem(item);
  };

  const hideDrawer = () => {
    setIsDrawerOpen(false);
    setActiveItem("");
  };

  return (
    <>
      <div className="News-wrapper">
        {/* <Header News={true}/> */}
        <div className="wrapper">
          <div className="content">
            {/* <div className="flex-center main-text primary-color">
                            <div className="navbar-link-text almarai-normal-white-20-6px active-title" style={{ fontSize: "30px" }}>
                          {  t(`${translationPath}news`)}
                            </div>
                        </div> */}
            <div className="news-img-container">
              <div className="news-img-item-wrpaer">
                {projects.map((project, index) => (
                  <Zoom zoom={0.5}>
                    <div key={index} className="relative-position news-s">
                      <img
                        loading="lazy"
                        onClick={() => showDrawer(project)}
                        className="news-img-item"
                        src={project.imageSrc}
                        alt={project.alt}
                      />
                      <div className="news-img-item-text">
                        <div className="top">
                          {(language === "en" && project.title_en) ||
                            project.title ||
                            ""}
                        </div>
                        <div className="top ">
                          {" "}
                          <span className="color-epda">
                            {(language === "en" && project.date) ||
                              project.date ||
                              ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Zoom>
                ))}
              </div>
            </div>

            <div id="body" className={isDrawerOpen ? "show-drawer" : ""}>
              <div id="drawer">
                <div className="x-icon">
                  <img
                    loading="lazy"
                    width="40px"
                    onClick={hideDrawer}
                    className="cloce-icon"
                    src={icon_close}
                    alt="icon_close"
                  />
                </div>

                <div className="slider-content">
                  <div className="title main-text-slider color-epda ">
                    {(language === "en" && ActiveItem.title_en) ||
                      ActiveItem.title ||
                      ""}
                  </div>
                  <div className="title title-description-project ">
                    {(language === "en" && ActiveItem.date) ||
                      ActiveItem.date ||
                      ""}
                  </div>
                  <div className="cENTER">
                    <img
                      loading="lazy"
                      className="slider_img"
                      style={{ marginTop: "20px" }}
                      src={ActiveItem.imageSrc}
                      alt={ActiveItem.alt}
                    />{" "}
                  </div>
                  {/* <div className="flex-center-w">
                  <div className="about-title  about-text-slider">
                    {ActiveItem.about}
                  </div>
                </div> */}
                  <div className="flex-center-w">
                    <div className="title main-text-slider">
                      {(language === "en" && ActiveItem.description_en) ||
                        ActiveItem.description ||
                        ""}
                    </div>
                  </div>

                  <div className="about-title  about-text-slider">
                    {(language === "en" && ActiveItem.about_en) ||
                      ActiveItem.about ||
                      ""}
                  </div>
                </div>
              </div>
              <div id="overlay" onClick={hideDrawer}></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default News;
