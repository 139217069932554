import Header from "../../components/Header/Header";

import "./Vacancies.css";
import Footer from "../../components/Footer/Footer";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Vacancies = () => {
  const parentTranslationPath = "Shared";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);
  const [fileName, setFileName] = useState("لا يوجد ملفات");
  const navigate = useNavigate();
  const handleFileChange = (event) => {
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      setFileName(fileInput.files[0].name);
    } else {
      setFileName("");
    }
  };

  return (
    <>
      <div className="Vacancies-wrapper">
        <Header ContactUs={true} />
        <div className="wrapper">
          {/* <div className="input-wrapper">
            <input type="text" placeholder="الاسم الكامل" />
          </div> */}
          <div className="flex-center main-text primary-color">
            <div
              className="navbar-link-text almarai-normal-white-20-6px active-title"
              style={{ fontSize: "21px" }}
            >
              {t(`${translationPath}Ourjobs`)}
            </div>
          </div>

          <div className="main-titele">مهندس مدني - تصميم المبنى</div>
          <div className="sub-titele">المملكة العربيه السعودية-الرياض</div>

          <div className="content">
            <div className="part-Vacancies">
              <div className="part-v">
                <div className="item-poient">
                  <div className="title-item-poient">تعريف الدور:</div>
                  <div className="des-item-poient">
                    يقود ويوجه مجموعة التصميم ويكون مسؤولاً عن المشاريع واسعة
                    النطاق بدءًا من تقديم العطاءات وحتى التسليم. تقديم التقارير
                    مباشرة إلى قائد الانضباط، يساهم عضو الفريق هذا في تطوير
                    الشركة من خلال دعم المديرين في التطور التدريجي لفلسفة
                    التصميم الخاصة بـ الابداع السعودي وأنشطة البحث والتطوير
                    الفني
                  </div>
                </div>

                <div className="item-poient">
                  <div>
                    <span className="title-item-poient">
                      المتطلبات الرئيسية:
                    </span>
                  </div>
                  <div className="item-row"> · الخلفية المعمارية</div>
                  <div className="item-row">
                    · حوالي 18-20 سنة من الخبرة مع 5 سنوات على الأقل في هذا
                    المنصب
                  </div>
                  <div className="item-row">
                    · الحد الأدنى 10 سنوات من الخبرة في دول مجلس التعاون الخليجي
                  </div>

                  <div className="item-row">
                    · مطلوب خبرة مع استشاريين من المستوى 1.{" "}
                  </div>
                  <div className="item-row">
                    {" "}
                    · مشاركة وإدارة قوية لأصحاب المصلحة.
                  </div>
                  <div className="item-row">
                    {" "}
                    · إدارة المشاريع / الفرق / المواعيد النهائية / الفرق البعيدة
                    واسعة النطاق.
                  </div>
                </div>

                <div className="item-poient">
                  <div>
                    <span className="title-item-poient">مؤهلات :</span>
                  </div>
                  <div className="item-row">
                    · خبرة المشاريع ومحفظة أعمالها: في المقام الأول، الضيافة
                    القوية + التطورات متعددة الاستخدامات. تجربة البرج السكني
                    والبيع بالتجزئة ميزة إضافية.
                  </div>
                  <div className="item-row">
                    · معرفة عميقة بالمبادئ التوجيهية لمشغلي الفنادق.
                  </div>
                  <div className="item-row">
                    · قوي من الناحية الفنية مع ذوق التصميم.
                  </div>

                  <div className="item-row">
                    · خبرة سابقة في إعداد وتسليم الحزم المعمارية في جميع مراحل
                    المشروع.
                  </div>
                  <div className="item-row">
                    {" "}
                    · المشاركة والتنسيق المستمر مع فرق المشروع.{" "}
                  </div>
                  <div className="item-row">
                    {" "}
                    · معرفة جيدة بمتطلبات الهيئة/عملية الموافقة.{" "}
                  </div>
                </div>

                <div className="item-poient">
                  <div>
                    <span className="title-item-poient">القسم:</span>
                    <span className="title-item-poient-white">
                      {" "}
                      الهندسة المعمارية
                    </span>
                  </div>
                </div>

                <div className="item-poient">
                  <div>
                    <span className="title-item-poient">نوع الوظيفة:</span>
                    <span className="title-item-poient-white"> دوام كامل</span>
                  </div>
                </div>

                <div className="item-poient">
                  <div>
                    <span className="title-item-poient">مكان الوظيفة: </span>
                    <span className="title-item-poient-white">
                      المملكة العربية السعودية
                    </span>
                  </div>
                </div>
              </div>

              <div className="part-v-2">
                <div className="text-glopel mext"> تقدم لهذا الشاغر </div>
                <div className="input-wrapper">
                  <input type="text" placeholder="الاسم الكامل" />
                </div>
                <div className="input-wrapper">
                  <input type="email" placeholder="البريد الإلكتروني" />
                </div>
                <div className="input-wrapper">
                  <input type="text" placeholder="رقم الهاتف" />
                </div>
                <div className="input-wrapper">
                  <textarea placeholder="الرسالة"></textarea>
                </div>

                <div className="text-glopel">
                  {" "}
                  *تحميل السيرة الذاتية / ارفاق ملفات{" "}
                </div>

                <div className="d-flex" style={{ marginTop: "20px" }}>
                  <div className="button-wrapper">
                    <div className="overlap-5">
                      <div className="text-wrapper-10">تحميل الملف</div>
                      <input
                        type="file"
                        className="file-input"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>

                  <div className="uplodetext"> {fileName}</div>
                </div>

                <div className="text-gree" style={{ marginTop: "20px" }}>
                  الأنواع المسموح بها:pdf,doc,docx
                </div>

                <div className="button-wrapper" style={{ marginTop: "55px" }}>
                  <div className="overlap-5">
                    <div className="text-wrapper-10">ارسال</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Vacancies;
{
  /* <div className="flex-center main-text primary-color">
              <div
                className="navbar-link-text almarai-normal-white-20-6px active-title"
                style={{ fontSize: "30px" }}
              >
                {t(`${translationPath}Careers`)}
              </div>
            </div> */
}
