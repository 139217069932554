import React, { useEffect, useState } from "react";
import next from "./../assets/images/icon/next.png";
import back from "./../assets/images/icon/back.png";

const Slideshow = ({ images, onClickopenModal }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // useEffect(() => {
  //   if (images && images.length > 0) {
  //     setCurrentIndex(0);
  //   }
  // }, [images]);

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  if (!images || images.length === 0) {
    return <div>No images to display</div>;
  }

  return (
    <div className="slideshow-container">
      {images[currentIndex] && !images[currentIndex].endsWith(".mp4") ? (
        <img
          src={images[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
          className="fade"
          onClick={() => {
            onClickopenModal(currentIndex);
          }}
        />
      ) : (
        <video
          width="511px"
          height="390px"
          controls
          onClick={(e) => e.stopPropagation()}
        >
          <source src={images[currentIndex]} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      {images.length > 1 && (
        <div className="button-container">
          <img
            alt="Next"
            onClick={goToNextSlide}
            src={next}
            className="Next-button"
          />
          <img
            alt="back"
            onClick={goToPrevSlide}
            src={back}
            className="back-button"
          />
        </div>
      )}
    </div>
  );
};

export default Slideshow;
