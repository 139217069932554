/* eslint-disable max-len */
import i18next from 'i18next';
import SharedEn from '../Layouts/I18n/en.json';
import SharedAr from '../Layouts/I18n/ar.json';
import { GlobalRerender } from './Middleware.Helper';

export function localizationInit() {
  i18next.init({
    interpolation: { escapeValue: false },
    fallbackLng: ['en', 'ar'],
    lng: 'ar',
    resources: {
      ar: { Shared: SharedAr },
      en: { Shared: SharedEn }
    },
  });

  const localizationData = JSON.parse(localStorage.getItem('EbdaaWebSiteLocalization')) || { currentLanguage: 'ar', isRtl: true };
  i18next.changeLanguage(localizationData.currentLanguage);

  if (localizationData.currentLanguage === 'ar') {
    document.body.setAttribute('class', 'rtl');
    document.body.setAttribute('dir', 'rtl');
    document.documentElement.lang = 'ar';
  }
}

export const languageChange = (currentLanguage) => {
  const isRtl = currentLanguage === 'ar';
  const direction = isRtl ? 'rtl' : '';
  localStorage.setItem('EbdaaWebSiteLocalization', JSON.stringify({ currentLanguage, isRtl }));
  document.body.setAttribute('class', direction);
  document.body.setAttribute('dir', direction);
// eslint-disable-next-line no-restricted-globals
  document.documentElement.lang = currentLanguage;
  i18next.changeLanguage(currentLanguage);
  GlobalRerender();
};

localizationInit();
