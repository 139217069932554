import React, { useCallback, useEffect, useState } from "react";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import { languageChange } from "../../Helper/TranslateMethod.Helper";
import { useTranslation } from "react-i18next";
import logowhite from "./../../assets/images/logo/logo-1-ar.png";
import main_icon from "./../../assets/images/icon/main_icon.svg";
import EN_main_icon_white from "./../../assets/images/icon/en-logo-w.png";
import EN_main_icon_green from "./../../assets/images/icon/logogree.png";
// import { Bounce } from "react-reveal";
const Header = ({
  News,
  projects,
  home,
  Services,
  employee,
  haring,
  about,
  ContactUs,
  isabsolute,
}) => {
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [language, setlanguage] = useState(
    (JSON.parse(localStorage.getItem("EbdaaWebSiteLocalization")) &&
      JSON.parse(localStorage.getItem("EbdaaWebSiteLocalization"))
        .currentLanguage) ||
      "ar"
  );
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      if (scrollTop > 30 && !isScrollingDown) {
        setIsScrollingDown(true);
      } else if (scrollTop < 31 && isScrollingDown) {
        setIsScrollingDown(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrollingDown]);

  const navigate = useNavigate();
  function scrollToTop() {
    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth' // Optional: Enables smooth scrolling
    // });
  }
  const redirectToServices = () => {
    scrollToTop();
    navigate("/services");
  };
  const redirectToCareers = () => {
    scrollToTop();
    navigate("/Careers");
  };
  const redirectToNews = () => {
    scrollToTop();
    navigate("/news");
  };
  const redirectToAbout = () => {
    scrollToTop();
    navigate("/about-us");
  };
  const redirectToContactUs = () => {
    scrollToTop();
    navigate("/contact-us");
  };
  const redirectToProjects = () => {
    scrollToTop();
    navigate("/projects");
  };

  const redirectToRoot = () => {
    navigate("/");
  };

  const openLinkInNewTab = () => {
    window.open("https://ebdaasaerp.com/Default", "_blank");
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const languageClicked = useCallback(languageChange);

  const parentTranslationPath = "Shared";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);

  useEffect(() => {
    const scrollTop = window.pageYOffset;
    if (scrollTop > 30) setIsScrollingDown(true);
    else setIsScrollingDown(false);
  }, []);

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const [showLinks, setShowLinks] = useState(false);

  const toggleLinks = () => {
    setShowLinks(!showLinks);
  };

  useEffect(() => {
    setIsScrollingDown(false);
  }, []);


  return (
    <>
      {/* <div className="menu-icon" onClick={toggleMobileMenu}>
        ☰ 
      </div>
      {isMobileMenuOpen && (
        <div className="MobileMenu">
          <div className="navbar-link-text">Item 1</div>
          <div className="navbar-link-text">Item 2</div>
        </div>
      )} */}

      <div
        className="re-design-ebdaa-sa-2 screen"
        style={{ position: isabsolute ? "absolute" : null }}
      >
        <div className="overlap-group">
          {/* <div className="heder"> */}
          <div className={`heder ${isScrollingDown ? "white-background" : ""}`}>
            {!home ? (
              <img
                onClick={redirectToRoot}
                height="69px"
                className={language !== "ar" ? "layer_1-en" : "layer_1"}
                src={
                  language !== "ar"
                    ? isScrollingDown
                      ? EN_main_icon_green
                      : EN_main_icon_white
                    : isScrollingDown
                    ? logowhite
                    : main_icon
                }
                alt="Layer_1"
              />
            ) : (
              // <Bounce left={language !== "ar"} right={language === "ar"}>
                <img
                  onClick={redirectToRoot}
                  height="69px"
                  className={language !== "ar" ? "layer_1-en" : "layer_1"}
                  src={
                    language !== "ar"
                      ? isScrollingDown
                        ? EN_main_icon_green
                        : EN_main_icon_white
                      : isScrollingDown
                      ? logowhite
                      : main_icon
                  }
                  alt="Layer_1"
                />
              // </Bounce>
            )}
            <div className="frame menu">
              <div
                className={`navbar ${
                  isScrollingDown ? "white-background-ss" : ""
                }`}
              >
                <div
                  onClick={redirectToAbout}
                  className={`navbar-link-text almarai-normal-white-20-6px ${
                    about && " color-epda active"
                  }`}
                >
                  {t(`${translationPath}about`)}
                </div>
                <div
                  onClick={redirectToServices}
                  className={`navbar-link-text almarai-normal-white-20-6px 
                ${Services && " color-epda active"}`}
                >
                  {t(`${translationPath}our_services`)}{" "}
                </div>
                <div
                  onClick={redirectToProjects}
                  className={`navbar-link-text almarai-normal-white-20-6px ${
                    projects && " color-epda active"
                  }`}
                >
                  {t(`${translationPath}our_projects`)}
                </div>

                <div
                  onClick={redirectToContactUs}
                  className={`navbar-link-text almarai-normal-white-20-6px ${
                    ContactUs && " color-epda active"
                  }`}
                >
                  {t(`${translationPath}ContactUs`)}
                </div>
                <div
                  onClick={openLinkInNewTab}
                  className={`navbar-link-text almarai-normal-white-20-6px
                 ${employee && " color-epda active"}`}
                >
                  {t(`${translationPath}employee_login`)}
                </div>
              </div>
            </div>

            {showLinks && (
              <div className="mobile-container ">
                <i className="fa fa-bars"></i>

                <div
                  id="myLinks"
                  className={showLinks ? "blockView" : "noneblockView"}
                >
                  <div className={`navbarMobile ${""}`}>
                    <div
                      onClick={redirectToAbout}
                      className={`navbar-link-text almarai-normal-white-20-6px ${
                        about && " color-epda active"
                      }`}
                    >
                      {t(`${translationPath}about`)}
                    </div>
                    <div
                      onClick={redirectToServices}
                      className={`navbar-link-text almarai-normal-white-20-6px 
                ${Services && " color-epda active"}`}
                    >
                      {t(`${translationPath}our_services`)}{" "}
                    </div>
                    <div
                      onClick={redirectToProjects}
                      className={`navbar-link-text almarai-normal-white-20-6px ${
                        projects && " color-epda active"
                      }`}
                    >
                      {t(`${translationPath}our_projects`)}
                    </div>

                    <div
                      onClick={redirectToContactUs}
                      className={`navbar-link-text almarai-normal-white-20-6px ${
                        ContactUs && " color-epda active"
                      }`}
                    >
                      {t(`${translationPath}ContactUs`)}
                    </div>
                    <div
                      onClick={openLinkInNewTab}
                      className={`navbar-link-text almarai-normal-white-20-6px
                 ${employee && " color-epda active"}`}
                    >
                      {t(`${translationPath}employee_login`)}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* <div className="group-2">{/* Other code </div>*/}

            <div className="frame-75971 frame">
              <div
                className={`en pointer ${
                  isScrollingDown ? "white-background-ss" : ""
                }`}
              >
                <span
                  onClick={() => {
                    const newLanguage = language === "ar" ? "en" : "ar";
                    languageChange(newLanguage);
                    setlanguage(newLanguage);
                  }}
                >
                  {" "}
                  {language === "ar" ? "EN" : "عربي"}
                </span>
              </div>
              <a className="icon-humberger" onClick={toggleLinks}>
                ☰{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
