import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./BrandCarousel.css"; // Import your custom styles
import { Zoom } from "react-reveal";

const BrandCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };
  const handleDoubleClick = (url) => {
    window.open(url, "_blank");
  };
  const [showAnimation, setShowAnimation] = useState(false);
  const [OurClinetGet, setOurClinetGet] = useState([]);
  useEffect(() => {
    setShowAnimation(true);
  }, []);
  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://www.ebdaasa.com.sa/MoreData/OurClinetGet.php"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const jsonData = await response.json();
      // Merge jsonData with existing PageDataProject state
      setOurClinetGet(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="Slider-wrpere">
      <div className="Slider-prand">
        {/* {owlItemsArray.map((item, index) => (
      <div
        className="con-dev relative-position" // Add relative-position class here
        key={index}
        onDoubleClick={() => handleDoubleClick(item.websitelink)}
      >
        <img className="con" src={item.imagelogo} alt={`Slide ${index + 1}`} />
      </div>
    ))} */}
        {OurClinetGet &&
          OurClinetGet.length > 0 &&
          OurClinetGet.map((item, index) => (
            <div
              className="con-dev relative-position" // Add relative-position class here
              key={index}
              onDoubleClick={() => handleDoubleClick(item.websitelink)}
            >
              <Zoom zoom={0.5}>
                <div
                  className="con con-div-img"
                  style={{ backgroundImage: `url('${item.imagelogo}')` }}
                  alt={`Slide ${index + 1}`}
                ></div>
              </Zoom>
            </div>
          ))}
      </div>
    </div>
  );
};

export default BrandCarousel;
