import project_1 from "./../../assets/images/cardspictures/man-holding-helmet-person-hold-hard-hat 1.png";
import project_2 from "./../../assets/images/cardspictures/project_2.png";
import project_3 from "./../../assets/images/cardspictures/project_3.png";
import project_4 from "./../../assets/images/cardspictures/draa.png";
import project_5 from "./../../assets/images/cardspictures/project_5.png";
import project_6 from "./../../assets/images/cardspictures/project_6.png";
import project_7 from "./../../assets/images/cardspictures/project_7.png";
import project_74 from "./../../assets/images/cardspictures/4.png";
import project_8 from "./../../assets/images/cardspictures/project_8.png";
import project_343 from "./../../assets/images/cardspictures/project_343.png";
import project_9 from "./../../assets/images/cardspictures/OLDDD.png";
import project_10 from "./../../assets/images/cardspictures/image 55.png";
import bulid11 from "./../../assets/images/shapes/bulid11.png";
import bulid12 from "./../../assets/images/shapes/bulid12.png";
import bulid13 from "./../../assets/images/shapes/bulid13.png";
import bulid14 from "./../../assets/images/shapes/bulid14.png";
import show1 from "./../../assets/images/slideshow/426197672_3359255624367363_6654667269141009121_n.jpg";
import show2 from "./../../assets/images/slideshow/426552518_1393669994854368_2351105761069048731_n.jpg";
import show3 from "./../../assets/images/slideshow/426668105_767165072052721_5973392344770789659_n.jpg";
import show4 from "./../../assets/images/slideshow/430055949_1435654477381074_8602622012243462037_n.jpg";
import dr from "./../../assets/images/BigImg/426285477_933892158012505_900121489843560413_n.jpg";

import Geometric1 from "./../../assets/images/BigImg/Geometric (1).jpg";
import Geometric2 from "./../../assets/images/BigImg/Geometric (2).png";
import Geometric3 from "./../../assets/images/BigImg/Geometric (3).png";
import Geometric4 from "./../../assets/images/BigImg/Geometric (4).png";
import Geometric5 from "./../../assets/images/BigImg/Geometric (5).png";
import Geometric6 from "./../../assets/images/BigImg/Geometric (6).png";
import Geometric7 from "./../../assets/images/BigImg/Geometric (7).jpg";
import Aestheticmodels1 from "./../../assets/images/BigImg/Aestheticmodels (1).png";
import Aestheticmodels2 from "./../../assets/images/BigImg/Aestheticmodels (2).png";
import Aestheticmodels3 from "./../../assets/images/BigImg/Aestheticmodels (3).png";
import Aestheticmodels4 from "./../../assets/images/BigImg/Aestheticmodels (4).png";
import government_parking1 from "./../../assets/images/BigImg/government_parking (1).jpg";
import government_parking2 from "./../../assets/images/BigImg/government_parking (2).jpg";
import government_parking3 from "./../../assets/images/BigImg/government_parking (3).jpg";
import government_parking4 from "./../../assets/images/BigImg/government_parking (4).jpg";
import Samhan2 from "./../../assets/images/BigImg/Samhan (2).jpg";
import Samhan1 from "./../../assets/images/BigImg/Samhan (1).jpg";
import Samhan3 from "./../../assets/images/BigImg/Samhan (3).jpg";
import Samhan4 from "./../../assets/images/BigImg/Samhan (4).jpg";
import Samhan5 from "./../../assets/images/BigImg/Samhan (5).jpg";
import Samhan6 from "./../../assets/images/BigImg/Samhan (6).png";
import Atlas1 from "./../../assets/images/BigImg/Atlas (1).jpg";
import Atlas2 from "./../../assets/images/BigImg/Atlas (2).jpg";
import Atlas3 from "./../../assets/images/BigImg/Atlas (3).jpg";
import Atlas4 from "./../../assets/images/BigImg/Atlas (4).jpg";
import Atlas5 from "./../../assets/images/BigImg/Atlas (5).jpg";
import Atlas6 from "./../../assets/images/BigImg/Atlas (6).jpg";
import Atlas7 from "./../../assets/images/BigImg/Atlas (7).jpg";
import Atlas8 from "./../../assets/images/BigImg/Atlas (8).jpg";
import Atlas9 from "./../../assets/images/BigImg/Atlas (9).jpg";
import Atlas10 from "./../../assets/images/BigImg/Atlas (10).jpg";
import Atlas11 from "./../../assets/images/BigImg/Atlas (11).jpg";
import Atlas12 from "./../../assets/images/BigImg/Atlas (12).jpg";

import Tableline0 from "./../../assets/images/BigImg/Tableline (0).png";
import Tableline1 from "./../../assets/images/BigImg/Tableline (1).jpg";
import Tableline2 from "./../../assets/images/BigImg/Tableline (2).jpg";
import Tableline3 from "./../../assets/images/BigImg/Tableline (3).jpg";
import Tableline4 from "./../../assets/images/BigImg/Tableline (4).jpg";
import Tableline5 from "./../../assets/images/BigImg/Tableline (5).jpg";
import Tableline6 from "./../../assets/images/BigImg/Tableline (6).jpg";
import Tableline7 from "./../../assets/images/BigImg/Tableline (7).jpg";
import DiriyahWa1 from "./../../assets/images/BigImg/DiriyahWall (1).jpg";
import DiriyahWa2 from "./../../assets/images/BigImg/DiriyahWall (2).jpg";
import DiriyahWa3 from "./../../assets/images/BigImg/DiriyahWall (3).jpg";
import DiriyahWa4 from "./../../assets/images/BigImg/DiriyahWall (4).jpg";
import DiriyahWa5 from "./../../assets/images/BigImg/DiriyahWall (5).jpg";
import DiriyahWa6 from "./../../assets/images/BigImg/DiriyahWall (6).jpg";
import TherbanPalace1 from "./../../assets/images/BigImg/TherbanPalace (1).jpg";
import TherbanPalace2 from "./../../assets/images/BigImg/TherbanPalace (2).jpg";
import TherbanPalace3 from "./../../assets/images/BigImg/TherbanPalace (3).jpg";
import TherbanPalace4 from "./../../assets/images/BigImg/TherbanPalace (4).jpg";
import TherbanPalace5 from "./../../assets/images/BigImg/TherbanPalace (5).jpg";
import hospitalitybuildings0 from "./../../assets/images/BigImg/hospitalitybuildings (0).jpg";
import hospitalitybuildings1 from "./../../assets/images/BigImg/hospitalitybuildings (1).jpg";
import hospitalitybuildings2 from "./../../assets/images/BigImg/hospitalitybuildings (2).jpg";
import hospitalitybuildings3 from "./../../assets/images/BigImg/hospitalitybuildings (3).jpg";
import hospitalitybuildings4 from "./../../assets/images/BigImg/hospitalitybuildings (4).jpg";
import hospitalitybuildings5 from "./../../assets/images/BigImg/hospitalitybuildings (5).jpg";
import hospitalitybuildings6 from "./../../assets/images/BigImg/hospitalitybuildings (6).jpg";
import hospitalitybuildings7 from "./../../assets/images/BigImg/hospitalitybuildings (7).jpg";
import hospitalitybuildings8 from "./../../assets/images/BigImg/hospitalitybuildings (8).jpg";
import hospitalitybuildings9 from "./../../assets/images/BigImg/hospitalitybuildings (9).jpg";
import UrbanAndscape1 from "./../../assets/images/BigImg/UrbanAndscape (1).jpg";
import UrbanAndscape2 from "./../../assets/images/BigImg/UrbanAndscape (2).jpg";
import UrbanAndscape3 from "./../../assets/images/BigImg/UrbanAndscape (3).jpg";
import UrbanAndscape4 from "./../../assets/images/BigImg/UrbanAndscape (4).jpg";
import UrbanAndscape5 from "./../../assets/images/BigImg/UrbanAndscape (5).jpg";
import UrbanAndscape6 from "./../../assets/images/BigImg/UrbanAndscape (6).jpg";
import UrbanAndscape7 from "./../../assets/images/BigImg/UrbanAndscape (7).jpg";
import residential_complex1 from "./../../assets/images/BigImg/residential_complex (1).jpg";
import residential_complex2 from "./../../assets/images/BigImg/residential_complex (2).jpg";
import residential_complex3 from "./../../assets/images/BigImg/residential_complex (3).jpg";
import residential_complex4 from "./../../assets/images/BigImg/residential_complex (4).jpg";
import FacultyHousing1 from "./../../assets/images/BigImg/FacultyHousing (1).png";
import FacultyHousing2 from "./../../assets/images/BigImg/FacultyHousing (2).png";
import FacultyHousing3 from "./../../assets/images/BigImg/FacultyHousing (3).png";
import FacultyHousing4 from "./../../assets/images/BigImg/FacultyHousing (4).png";
import FacultyHousing5 from "./../../assets/images/BigImg/FacultyHousing (5).png";
import FacultyHousing6 from "./../../assets/images/BigImg/FacultyHousing (6).png";
import FacultyHousing7 from "./../../assets/images/BigImg/FacultyHousing (7).png";
import factory1 from "./../../assets/images/BigImg/factory1 (1).png";
import factory2 from "./../../assets/images/BigImg/factory1 (2).png";

const projects = [
  {
    imageSrc: [project_1, dr, show1, show2, show3, show4],
    alt: "serves_0",
    about: "UrgenthealthcentersAbout",
    title: "Urgenthealthcenters",
    description: "MinistryofHealth",
  },
  {
    imageSrc: [
      project_3,
      Atlas12,
      Atlas1,
      Atlas2,
      Atlas3,
      Atlas4,
      Atlas5,
      Atlas6,
      Atlas7,
      Atlas8,
      Atlas9,
      Atlas10,
      Atlas11,
    ],
    alt: "serves_0",
    descriptionTitle: "ClientGeneralAuthority",
    about: "projectAtlasApout",
    title: "projectAtlas",
    description: "ministrytourism",
  },
  // {
  //   imageSrc: [
  //     project_10,
  //     Tableline0,
  //     Tableline1,
  //     Tableline2,
  //     Tableline3,
  //     Tableline4,
  //     Tableline5,
  //     Tableline6,
  //     Tableline7,
  //   ],
  //   alt: "serves_0",
  //   about: "",
  //   title: "designtableline",
  //   description: "HeritageAuthorityqqw",
  // },
  {
    imageSrc: [
      project_2,
      Samhan6,
      Samhan2,
      Samhan3,
      Samhan4,
      Samhan5,
      Samhan1,
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/photo1712015219(7).jpeg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/photo1712015219(6).jpeg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/photo1712015219(5).jpeg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/photo1712015219(4).jpeg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/photo1712015219(3).jpeg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/photo1712015219(2).jpeg",
    ],
    alt: "serves_0",
    about: "Design-and-restoration-about",
    title: "Design-and-restoration-of-Samhan-neighborhood",
    description: "Diriyah-Gate-Development-Authority",
  },

  {
    imageSrc: [
      project_4,
      DiriyahWa1,
      DiriyahWa2,
      DiriyahWa3,
      DiriyahWa4,
      DiriyahWa5,
      DiriyahWa6,
    ],
    alt: "serves_0",
    about: "",
    title: "DiriyahWall",
    description: "DiriyahGateDevelopment",
  },
  {
    imageSrc: [
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/23432df.png",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430315166_1435890507036851_40220834434770996_n.jpg",
      project_7,
      Geometric1,
      Geometric2,
      Geometric3,
      Geometric4,
      Geometric5,
      Geometric6,
      Geometric7,
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/429925934_783368847055006_7920181901107943351_n.png",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/429984596_414387014515016_5237770046310581078_n.png",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430041467_2148688585484319_6823430937796726783_n.png",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430041468_393286093671385_8284000911545644065_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430069438_442239084980280_1517294158044401667_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430074246_221357897707146_1531888014104823018_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430081472_887851666361830_6756177815578054280_n.png",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430109386_7952252811468852_8315356870859959322_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430323029_937235697704944_8995920458827258043_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430354804_1093197918465979_8062885977721246532_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/433149967_1136745494428789_5917843623309208182_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/433175564_385600930929211_3902606049110658118_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/433195135_1413554722635238_9120649000798030055_n.png",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/433211139_967188721478153_3633098981454922193_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/433266295_1146761033167468_5565191677152539774_n.jpg",
    ],
    alt: "serves_0",
    title: "QasrBinBarqoushtitle",
    about: "QasrBinBarqoushtitledES",
    description: "GeneralAuthority",
  },
  {
    id: "1",
    imageSrc: [
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/426699594_945349873660481_5816270707255531491_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/ksaking.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/444c6457d9e614738f7ed8f90448ed50.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/666f8530-b8ec-47b1-9e22-197c0122fb96-1.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/asd32rdcvdqaw.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/bc65daa575df2431191addb879738f37.webp",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/Ez1GCODX0AIKw7R.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/ln_12_1.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/photo17119196sdffsd.jpeg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/photo171191965zzx.jpeg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/photo1711919648cz.jpeg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/photo1711919653%20(3).jpeg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/photo1711919653.jpeg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/photo1711919653asd.jpeg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/qwe321423dqe.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/d3c8bdad-dc3d-4cb5-b6cb-f516399bb0fc.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/photo1711919647.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/ceasasd.mp4",
    ],
    alt: "alt",
    className: "",
    about:
      "تشرفت الشركة بتعاونها مع وزارة الثقافة بأعمال الدراسات والتصميم لمشروع “سمو ولي العهد لترميم المساجد التاريخية“  والذي اشتمل على التوثيق المعماري وإعداد مخططات التأهيل والتطوير لعدد (130) مسجد تاريخي موزعة حول مناطق المملكة",
    title: "مشروع سمو ولي العهد لتطوير المساجد التاريخية",
    description: "وزارة الثقافة – هيئة التراث",
    descriptionTitle: "",
    size: "",
    about_en:
      "The company had the honor of collaborating with the Ministry of Culture on the studies and design work for the project 'HRH Crown Prince's Project for the Restoration of Historical Mosques,' which included architectural documentation and the preparation of rehabilitation and development plans for (130) historical mosques distributed around the Kingdom's regions.",
    title_en:
      "HRH Crown Prince's Project for the Development of Historical Mosques",
    description_en: "Ministry of Culture - Heritage Authority",
    descriptionTitle_en: "",
    size_en: "",
  },
  {
    imageSrc: [
      project_5,
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/429965931_889597769519711_9004773449817082294_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430080221_333694079708755_2296871095994973316_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430118440_1500805213979935_4501689672204517103_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430283479_1084034009551143_9113655287113701053_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430314988_942818390572947_4600212283783306450_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430360953_951931189825515_2385896415376600390_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430361955_1263072041747285_5940292139683766509_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/433104500_386814450947511_5759282180743243479_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/433180463_1830854370751382_2637264652602665169_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/433373830_1125043428694121_4472969891866651434_n.jpg",
    ],
    alt: "serves_0",
    about: "",

    title: "FacultyHousing",
    descriptionTitle: "FacultyHousingLoaction",
    size: "FacultyHousingsize",
    description: "FacultyHousingdescription",
  },

  {
    imageSrc: [
      project_74,
      hospitalitybuildings0,
      hospitalitybuildings1,
      hospitalitybuildings2,
      hospitalitybuildings3,
      hospitalitybuildings4,
      hospitalitybuildings5,
      hospitalitybuildings6,
      hospitalitybuildings7,
      hospitalitybuildings8,
      hospitalitybuildings9,
    ],

    alt: "serves_0",
    about: "",
    title: "hospitalitybuildings",
    description: "MinistryTransport",
  },
  {
    imageSrc: [
      project_343,
      Aestheticmodels1,
      Aestheticmodels2,
      Aestheticmodels3,
      Aestheticmodels4,
    ],
    alt: "serves_0",
    about: "",
    title: "Aestheticmodels",
    description: "RawdatAlHabassquares",
  },
  {
    imageSrc: [
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/trppanm.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/426836745_1507598169845773_2673577171220436506_n.png",
      // project_8,
      TherbanPalace1,
      TherbanPalace2,
      TherbanPalace3,
      TherbanPalace4,
      TherbanPalace5,
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/426699669_1077166410027616_1498482790352236097_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/426807307_1601149343992482_3893710781854321242_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/426819141_1640559220091401_7620272925427363937_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/429956620_1465238094086388_9003370029775614892_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/429985523_1508192043466636_2987386604991125710_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430057767_812186194055286_6764560538909811255_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430069089_429962112739801_6432599661368435214_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430073817_1084786949265346_701794944523369450_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430091850_1548652989043090_660081011967849923_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430113700_326746030015016_8394621735637294032_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430294229_876091410937553_2604294319092422284_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430386144_455027790290521_1443049316505909032_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430413950_715203334160180_8391887636648415780_n.png",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430428487_3522068314790505_4583752853482050178_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430488197_275835932233993_1655328074328173625_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/433090802_1854452475015904_1270840148246098296_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/433145315_902478761671700_2653053078148059468_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/433168123_1120925548946541_2200951965849676870_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/433168136_1232220814411512_5417295678151868316_n.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/433255914_441333638292082_4355079450148610974_n.jpg",
    ],
    alt: "serves_0",
    about: "ThurbanPalaceabout",
    title: "ThurbanPalace",
    description: "General_Authority_for_Tourism_and_National_Heritage",
  },
  {
    imageSrc: [
      project_9,
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/photo1711930363(3).jpeg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/photo1711930363(2).jpeg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/photo1711930363(1).jpeg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/photo1711930363.jpeg",
      // "https://www.ebdaasa.com.sa/MoreData/ImagesMore/426699688_436560912228100_4362964858738077987_n.jpg",
      // "https://www.ebdaasa.com.sa/MoreData/ImagesMore/429963468_1151188652894251_2090523354415519389_n.jpg",
      // "https://www.ebdaasa.com.sa/MoreData/ImagesMore/429984591_951166693046680_5977704765517301861_n.jpg",
      // "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430063445_388735933947700_4061321871413687914_n.jpg",
      // "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430069310_816834180276679_7796491543578019044_n.jpg",
      // "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430101622_289979680805394_8353998935812681049_n.jpg",
      // "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430108948_3787139188181970_735731106294040803_n.jpg",
      // "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430465348_261862106977359_2111709659013775276_n.jpg",
      // "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430782369_295248773600628_3322103263889930859_n.jpg",
      // "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430831477_7339544849469248_8778048520092929223_n.jpg",
      // "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430992130_1424651048166468_3431038352308767140_n.jpg",
      // "https://www.ebdaasa.com.sa/MoreData/ImagesMore/433104497_1572597073525723_3445691060152624944_n.jpg",
      // "https://www.ebdaasa.com.sa/MoreData/ImagesMore/433235340_1072160747183043_8727520120237773587_n.jpg",
      // "https://www.ebdaasa.com.sa/MoreData/ImagesMore/433175568_929776002130572_2341078632422491220_n.jpg",
      // "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430343704_445793211134506_7111623232320845551_n.jpg",
      // "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430354568_2297458047123286_3026975215872272665_n.jpg",
      // "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430433188_303093876138310_444861908919736411_n.jpg",
      // "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430484083_787285926228386_4954462955878209174_n.jpg",
      // "https://www.ebdaasa.com.sa/MoreData/ImagesMore/433202261_724852539725141_4966250560761677887_n.jpg",
    ],
    alt: "serves_0",
    about: "",
    title: "Studyinteriordecoration",
    description: "ZahranRealEstate",
  },
  {
    imageSrc: [bulid12, factory1, factory2],
    alt: "serves_0",
    about: "SudairArea",
    title: "Studysudair",
    descriptionTitle: "LocationSudair",
    description: "factory",
  },
  {
    imageSrc: [
      bulid11,
      UrbanAndscape6,
      UrbanAndscape7,
      UrbanAndscape1,
      UrbanAndscape2,
      UrbanAndscape3,
      UrbanAndscape4,
      UrbanAndscape5,
    ],

    alt: "serves_0",
    about: "",
    descriptionTitle: "LoactionAsirRegionMunicipality",
    title: "Study-and-design-of-the-urban-landscape",
    description: "AsirRegionMunicipality",
  },
  {
    imageSrc: [
      bulid13,
      residential_complex1,
      residential_complex2,
      residential_complex3,
      residential_complex4,
    ],

    alt: "serves_0",
    about: "area_64670_square_meters",
    title: "study_design_supervision_complex",
    descriptionTitle: "location_industrial_city_2_riyadh",
    description: "residential_complex",
  },
  {
    imageSrc: [
      bulid14,
      government_parking1,
      government_parking2,
      government_parking3,
      government_parking4,
    ],
    alt: "serves_0",
    descriptionTitle: "location_riyadh",
    about: "area_30000_square_meters",
    title: "design_printing_government_parking",
    description: "ministry_of_finance",
  },
  {
    imageSrc: [
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/enganrtert.jpg",
      "https://www.ebdaasa.com.sa/MoreData/ImagesMore/4%20616438.jpg",
    ],
    alt: "alt",
    className: "",
    about: "",
    title: "الدعم والاشراف لإدارة المدينة الجامعية ",
    description: " جامعة الحدود الشمالية",
    descriptionTitle: "",
    size: "",
    about_en: "",
    title_en: "Design and Supervision in University Management",
    description_en: "Secretary of Northern Border University",
    descriptionTitle_en: "",
    size_en: "",
  },
];
export default projects;
// {
//   "imageSrc": [
//     "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430148739_947223876695886_1143744014120819586_n.jpg",
//     "https://www.ebdaasa.com.sa/MoreData/ImagesMore/430967010_1470269240277988_542817913444979503_n.jpg"
//   ],
//   "alt": "alt",
//   "className": "",
//   "about": "",
//   "title": "مشروع التوثيق المعماري لمباني التراث العمراني",
//   "description": "مناطق مختلفة",
//   "descriptionTitle": "",
//   "size": "",
//   "about_en": "",
//   "title_en": "Architectural Documentation Project for Urban Heritage Buildings",
//   "description_en": "Various Areas",
//   "descriptionTitle_en": "",
//   "size_en": ""
// },
