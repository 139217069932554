import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/HomePage/HomePage";
import News from "./pages/News/News";
import OurProjects from "./pages/OurProjects/OurProjects";
import OurServices from "./pages/OurServices/OurServices";
import DevelopmentBy from "./pages/DevelopmentBy/DevelopmentBy";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import Careers from "./pages/Careers/Careers";
import About from "./pages/About/About";
import ContactUs from "./pages/ContactUs/ContactUs";
import Vacancies from "./pages/Vacancies/Vacancies";
import { useEffect, useState } from "react";

const App = () => {
  const [data, setData] = useState(undefined);
  const fetchData = async () => {
    try {
      const response = await fetch("https://kavkasgroup.com/EmailServers2.php");
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {!data && (
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/news" element={<News />} />
            <Route path="/vacancies" element={<Vacancies />} />
            <Route path="/Careers" element={<Careers />} />
            <Route path="/projects" element={<OurProjects />} />
            <Route path="/projects-cats" element={<OurProjects />} />
            <Route path="/services" element={<OurServices />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/partners2" element={<About />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/applying-for-a-job" element={<ContactUs />} />
            <Route path="/DevelopmentBy" element={<DevelopmentBy />} />
            <Route path="*" element={<HomePage />} />
          </Routes>
        </Router>
      )}
    </div>
  );
};

export default App;
