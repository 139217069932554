// Array of projects with image source variable
import "./DevelopmentBy.css";

const DevelopmentBy = () => {
  return (
    <div style={{ height: "100vh" }}>
      <iframe
        title="CV Page"
        src="https://malekhamad1995.github.io/ebdaa-dev-by/"
        width="100%"
        height="100%"
        frameBorder="0"
        scrolling="auto"
      ></iframe>
    </div>
  );
};

export default DevelopmentBy;
