import React, { useState, useEffect } from "react";
import "./OurServices.css"; // Import your CSS file
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import LazyLoad from "react-lazyload";
import { useTranslation } from "react-i18next";
import icon_close from "./../../assets/images/icon/colse.png";
import designArchitectureImage from "./../../assets/images/cardspictures/man-holding-helmet-person-hold-hard-hat 1.png";
import designConsultancyImage from "./../../assets/images/cardspictures/serves_2.png";
import soilTestingImage from "./../../assets/images/cardspictures/serves_1.png";
import heritageUrbanismImage from "./../../assets/images/cardspictures/serves_0.png";
import projectManagementImage from "./../../assets/images/cardspictures/serves_7.jpg";
import urbanPlanningImage from "./../../assets/images/cardspictures/serves_6.png";
import interiorDecorationImage from "./../../assets/images/cardspictures/serves_5.png";
import landSurveyingImage from "./../../assets/images/cardspictures/serves_8.png";
import projectDecompositionImage from "./../../assets/images/cardspictures/serves_4.png";
import "./OurServices.css";
import { Zoom } from "react-reveal";
const OurServices = () => {
  const parentTranslationPath = "Shared";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [ActiveItem, setActiveItem] = useState("");
  const [showAnimation, setShowAnimation] = useState(false);
  useEffect(() => {
    setShowAnimation(true);
  }, []);

  const showDrawer = (item) => {
    setIsDrawerOpen(true);
    setActiveItem(item);
  };
  const hideDrawer = () => {
    setIsDrawerOpen(false);
    setActiveItem("");
  };
  const projects = [
    {
      imageSrc: designConsultancyImage,
      SlideimageSrc:
        "https://www.ebdaasa.com.sa/MoreData/ImagesMore/OurServices4.jpg",
      alt: "serves_0",
      about: t(`${translationPath}DesignConsulting-des`),
      title: t(`${translationPath}DesignConsulting`),
      description: "",
    },
    {
      imageSrc: projectManagementImage,
      alt: "serves_0",
      SlideimageSrc:
        "https://www.ebdaasa.com.sa/MoreData/ImagesMore/324sqadadcasdas.jpg",
      about: t(`${translationPath}Urbanheritage-des`),
      title: t(`${translationPath}Urbanheritage`),
      description: "",
    },
    {
      imageSrc: interiorDecorationImage,
      alt: "serves_0",
      about: t(`${translationPath}internal-decoration-des`),
      title: t(`${translationPath}internal-decoration`),
      SlideimageSrc:
        "https://www.ebdaasa.com.sa/MoreData/ImagesMore/photo_5857324172215173320_y.jpg",
      description: "",
    },

    {
      imageSrc: urbanPlanningImage,
      alt: "serves_0",
      SlideimageSrc:
        "https://www.ebdaasa.com.sa/MoreData/ImagesMore/OurServices1.jpg",
      about: t(`${translationPath}project-mangment-des`),
      title: t(`${translationPath}project-mangment`),
      description: "",
    },

    {
      imageSrc: designArchitectureImage,
      alt: "serves_0",
      description: "",
      SlideimageSrc:
        "https://www.ebdaasa.com.sa/MoreData/ImagesMore/111111111111dsacc.png",
      about: t(`${translationPath}DesignArchitect-des`),
      title: t(`${translationPath}ArchitecturalDesign`),
    },
    {
      imageSrc: projectDecompositionImage,
      alt: "serves_0",
      about: t(`${translationPath}decoration-des`),
      SlideimageSrc:
        "https://www.ebdaasa.com.sa/MoreData/ImagesMore/OurServices7.jpg",
      title: t(`${translationPath}decoration`),
      description: "",
    },
    {
      imageSrc: landSurveyingImage,
      alt: "serves_0",
      about: t(`${translationPath}eng-size-des`),
      SlideimageSrc:
        "https://www.ebdaasa.com.sa/MoreData/ImagesMore/OurServices6.jpg",
      title: t(`${translationPath}eng-size`),
      description: "",
    },

    {
      imageSrc: heritageUrbanismImage,
      alt: "serves_0",
      SlideimageSrc:
        "https://www.ebdaasa.com.sa/MoreData/ImagesMore/OurServices5.jpg",
      about: t(`${translationPath}safety-security-des`),
      title: t(`${translationPath}safety-security`),
      description: "",
    },

    {
      imageSrc: soilTestingImage,
      alt: "serves_0",
      SlideimageSrc:
        "https://www.ebdaasa.com.sa/MoreData/ImagesMore/OurServices9.jpg",
      title: t(`${translationPath}SoilFoundationExamination`),
      about: t(`${translationPath}SoilFoundationExamination-des`),
      description: "",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="OurServices-wrapper">
        <Header Services={true} />
        <div className="wrapper">
          <div className="content">
            <div className="flex-center main-text primary-color">
              <div
                className="navbar-link-text almarai-normal-white-20-6px active-title"
                style={{ fontSize: "21px" }}
              >
                {t(`${translationPath}our_services`)}
              </div>
            </div>
            <div className="flex-centerjustify">
              <div className="flex-centerjustify img-container">
                <div
                  className={`img-item-wrpaer ${showAnimation ? "show" : ""}`}
                >
                  {projects.map((project, index) => (
                    // <Zoom zoom={0.5}>
                    <div
                      key={index}
                      className={`relative-position ${
                        showAnimation ? "show" : ""
                      }`}
                    >
                      <LazyLoad height={200} offset={100} once>
                        <img
                          onClick={() => showDrawer(project)}
                          loading="lazy"
                          width={"342px"}
                          height={"342px"}
                          className="img-item"
                          src={project.imageSrc}
                          alt={project.alt}
                        />
                      </LazyLoad>
                      <div className="img-item-text">
                        <div className="title title-text fontsize16">
                          {project.title}
                        </div>
                        <div className="title title-description">
                          {project.description}
                        </div>
                      </div>
                    </div>
                    // </Zoom>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div id="body" className={isDrawerOpen ? "show-drawer" : ""}>
            <div id="drawer">
              <div className="x-icon">
                <img
                  loading="lazy"
                  width="40px"
                  onClick={hideDrawer}
                  className="cloce-icon"
                  src={icon_close}
                  alt="icon_close"
                />
              </div>
              <div className="slider-content">
                <div
                  className="color-epda title main-text-slider "
                  style={{ paddingBottom: "25px" }}
                >
                  {ActiveItem.title + "    " + ActiveItem.description}
                </div>
                <div className="cENTER">
                  <img
                    loading="lazy"
                    className="slider_img"
                    src={ActiveItem.SlideimageSrc}
                    alt={ActiveItem.alt}
                  />
                </div>
                <div className="title main-text-slider">
                  {ActiveItem.descriptionTitle}
                </div>
                <div className="flex-center-w">
                  <div className="about-title  about-text-slider">
                    {ActiveItem.about}
                  </div>
                </div>
              </div>
            </div>
            <div id="overlay" onClick={hideDrawer}></div>
          </div>
        </div>
        <Footer transparent={true} />
      </div>
    </>
  );
};

export default OurServices;
