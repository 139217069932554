// Array of projects with image source variable
import { useEffect, useState } from "react";
import "./ContactUs.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useTranslation } from "react-i18next";
import faceIcon from "./../../assets/images/icon/face.svg";
import insgIcon from "./../../assets/images/icon/insg.svg";
import linkendIcon from "./../../assets/images/icon/linkend.svg";
import youtubeIcon from "./../../assets/images/icon/youtube.svg";
import News from "../News/News";
import Careers from "../Careers/Careers";
import { Zoom } from "react-reveal";
const ContactUs = () => {
  const parentTranslationPath = "Shared";
  const translationPath = "";
  const googleMapsLink =
    "https://www.google.com/maps/place/شركة+الإبداع+السعودي+للاستشارات+الهندسية+Al-ebdaa+Alsaudi+Consultants%E2%80%AD/@24.7689297,46.6913272,15z/data=!4m6!3m5!1s0x3e2efd51bd9e8b5b:0xb180a4807836b267!8m2!3d24.7689297!4d46.6913272!16s%2Fg%2F11c5b3bzf6?entry=ttu";

  const handleClick = () => {
    window.open(googleMapsLink, "_blank");
  };
  const { t } = useTranslation(parentTranslationPath);
  const [activeitem, setactiveitem] = useState(1);
  const [ViewMassge, setViewMassge] = useState(null);
  const [showViewMassge, setshowViewMassge] = useState(true);
  useEffect(() => {
    const root = document.querySelector(":root");
    const pointer = (e) => {
      root.style.setProperty("--y", e.pageY + "px");
    };
    window.addEventListener("pointermove", pointer);
    window.addEventListener("pointerdown", pointer);

    return () => {
      window.removeEventListener("pointermove", pointer);
      window.removeEventListener("pointerdown", pointer);
    };
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Check if any of the required fields are empty
    if (!name || !email || !contactNo || !message) {
      // If any field is empty, do nothing and return
      return;
    }
  
    // Set success message
    setViewMassge(t(`${translationPath}SendDone`));
    setshowViewMassge(false);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("contactNo", contactNo);
    formData.append("message", message);
  
    try {
      const response = await fetch("https://scfec.com/contact.php", {
        method: "POST",
        body: formData,
        mode: "cors", // Allow CORS
      });
      const data = await response.json();
  
      // Reset form fields after successful submission
      setName("");
      setEmail("");
      setContactNo("");
      setMessage("");
    } catch (error) {
      console.error("Error:", error);
      // Reset form fields if an error occurs
      setName("");
      setEmail("");
      setContactNo("");
      setMessage("");
    }
  };
  

  useEffect(() => {
    setTimeout(() => {
      setViewMassge("");
      setshowViewMassge(true);
    }, 6000);
  }, [ViewMassge]);
  return (
    <div
      className={
        (activeitem === 1 && "ContactUs-wrapper ContactUs-background") ||
        (activeitem === 0 && "ContactUs-wrapper news-background") ||
        "ContactUs-wrapper news-Careers" ||
        "ContactUs-wrapper"
      }
    >
      <Header ContactUs={true} />
      <div className="wrapperContactUs">
        <div className="content" style={{ zIndex: "88" }}>
          <div className="flex-center main-text  ContactUs-flex">
            <div
              className={`navbar-link-text almarai-normal-white-20-6px ${
                activeitem === 0 && "active-title primary-color"
              }`}
              onClick={() => setactiveitem(0)}
              style={{ fontSize: "21px" }}
            >
              {t(`${translationPath}news`)}
            </div>
            <div
              onClick={() => setactiveitem(1)}
              className={`navbar-link-text almarai-normal-white-20-6px ${
                activeitem === 1 && "active-title primary-color"
              }`}
              style={{ fontSize: "21px" }}
            >
              {t(`${translationPath}ContactUs`)}
            </div>
            <div
              className={`navbar-link-text almarai-normal-white-20-6px ${
                activeitem === 2 && "active-title primary-color"
              }`}
              onClick={() => setactiveitem(2)}
              style={{ fontSize: "21px" }}
            >
              {t(`${translationPath}employment`)}
            </div>
          </div>

          {activeitem === 1 && (
            <Zoom bottom>
              <div className="ContactUs-content">
                <div className="background">
                  <div className="container">
                    <div className="contact">
                      <div className="contact-header">
                        <div className="contact-header-left">
                          <div className="contact-header-button close"></div>
                          <div className="contact-header-button maximize"></div>
                          <div className="contact-header-button minimize"></div>
                        </div>
                      </div>
                      <div className="contact-body">
                        <div className="contact-body-item left">
                          <div className="app-title">
                            <span>
                              {" "}
                              {t(`${translationPath}CONTACT`)}{" "}
                              {t(`${translationPath}US`)}
                            </span>
                          </div>
                          <div
                            className="app-contact-cursor"
                            onClick={handleClick}
                          >
                            {" "}
                            {t(`${translationPath}Contactlovation`)} :{" "}
                            {t(`${translationPath}loaction`)}{" "}
                          </div>
                          <div className="app-contact">
                            {" "}
                            <div>
                              {" "}
                              {t(`${translationPath}ContactINFO`)} :
                              966114567137+{" "}
                            </div>
                            &nbsp;{" "}
                            <div>
                              {" "}
                              {t(`${translationPath}ContactINFO2`)} :
                              966508411155+{" "}
                            </div>
                          </div>

                          {/* <div className="app-contact">   {t(`${translationPath}Contactfax`)}  :  966114567138 +</div> */}

                          <div className="social-media-ContactUs">
                            <img
                              src={faceIcon}
                              className="social-media-icon"
                              alt="Facebook"
                              onClick={() =>
                                window.open(
                                  "https://www.facebook.com/AlebdaaAlsaudi/?locale=ar_AR",
                                  "_blank"
                                )
                              }
                            />
                            <img
                              src={insgIcon}
                              className="social-media-icon"
                              alt="Instagram"
                              onClick={() =>
                                window.open(
                                  "https://twitter.com/alebdaa_alsaudi?lang=ar",
                                  "_blank"
                                )
                              }
                            />
                            <img
                              src={linkendIcon}
                              className="social-media-icon"
                              alt="LinkedIn"
                              onClick={() =>
                                window.open(
                                  "https://sa.linkedin.com/in/الابداع-السعودي-للاستشارات-الهندسية-7a19ba13a",
                                  "_blank"
                                )
                              }
                            />
                            <img
                              src={youtubeIcon}
                              className="social-media-icon"
                              alt="YouTube"
                              onClick={() =>
                                window.open(
                                  "https://www.youtube.com/@al-ebdaaalsaudiconsultants554",
                                  "_blank"
                                )
                              }
                            />
                            <div className="social-media-email">
                              {" "}
                              info@ebdaasa.com.sa
                            </div>
                          </div>
                        </div>
                        <div className="contact-body-item">
                          <form onSubmit={handleSubmit}>
                            <div className="app-form">
                              <div className="app-form-group">
                                <input
                                  className="app-form-control"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  placeholder={t(`${translationPath}NAME`)}
                                />
                              </div>
                              <div className="app-form-group">
                                <input
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  className="app-form-control"
                                  placeholder={t(`${translationPath}EMAIL`)}
                                />
                              </div>
                              <div className="app-form-group">
                                <input
                                  className="app-form-control"
                                  value={contactNo}
                                  onChange={(e) => setContactNo(e.target.value)}
                                  placeholder={t(`${translationPath}CONTACTNO`)}
                                />
                              </div>
                              <div className="app-form-group message">
                                <input
                                  className="app-form-control"
                                  value={message}
                                  onChange={(e) => setMessage(e.target.value)}
                                  placeholder={t(`${translationPath}MESSAGE`)}
                                />
                              </div>
                              <div className="app-form-group buttons">
  {      showViewMassge&&                        <button
                                  type="submit"
                                  className="app-form-button"
                                >
                                  {t(`${translationPath}SEND`)}
                                </button>}

                                <button
                                  style={{ color: "white" }}
                                  className="app-form-button"
                                >
                                  {ViewMassge}
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </Zoom>
          )}
          {activeitem === 2 && <Careers />}
          {activeitem === 0 && <News />}
        </div>
        {activeitem === 1 && (
          <div className="ContactUs-bb">
            <div id="bg"></div>
            <div id="bg_mask"></div>
          </div>
        )}
      </div>
      <Footer transparent={true} />
    </div>
  );
};

export default ContactUs;
